import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import NewModal from "../Components/ModalView";

function getPriceToPaymentRate(downPayment, marketValue) {
  let priceToPaymentRate;

  if (downPayment >= marketValue * 0.1 && downPayment < marketValue * 0.11) {
    priceToPaymentRate = 0.0021;
  } else if (
    downPayment >= marketValue * 0.11 &&
    downPayment < marketValue * 0.12
  ) {
    priceToPaymentRate = 0.0016;
  } else if (
    downPayment >= marketValue * 0.12 &&
    downPayment < marketValue * 0.13
  ) {
    priceToPaymentRate = 0.001;
  } else if (
    downPayment >= marketValue * 0.13 &&
    downPayment < marketValue * 0.14
  ) {
    priceToPaymentRate = 0.0006;
  } else if (
    downPayment >= marketValue * 0.14 &&
    downPayment < marketValue * 0.15
  ) {
    priceToPaymentRate = 0.0004;
  } else if (downPayment >= marketValue * 0.15) {
    priceToPaymentRate = 0.0002;
  } else {
    priceToPaymentRate = 0;
  }

  return priceToPaymentRate;
}

function getMinPaymentValue(marketValue, downPayment, paymentMonths) {
  if (isNaN(parseFloat(downPayment))) {
    downPayment = 0;
  }

  // let priceToPaymentRate = getPriceToPaymentRate(downPayment, marketValue);
  let priceToPaymentRate = 0.00195;
  let financialCostRate = 0.00805;

  if (paymentMonths <= 40) {
    priceToPaymentRate = 0.0021;
    financialCostRate = 0.0099;
  }

  const principalPayment = marketValue * priceToPaymentRate;
  const rentPayment = marketValue * financialCostRate;

  return [principalPayment, rentPayment];
}
function calculateMortgagePayment(rate, numPeriods, value) {
  return (
    (rate * Math.pow(1 + rate, numPeriods) * value) /
    (Math.pow(1 + rate, numPeriods) - 1)
  );
}
function calculateNumberOfMonths(minPayment, propertyPrice, downPayment) {
  if (isNaN(parseFloat(downPayment))) {
    downPayment = 0;
  }
  let base = minPayment[0];
  let value = 0;
  let count = 1;
  while (value < propertyPrice * 0.2 - downPayment) {
    if (count % 12 === 0 && count !== 0) {
      base += base * 0.0491;
    }
    value += base;
    count += 1;
  }

  return (
    <span className="p-val">
      {count}-{count + 2} Meses (aprox)
    </span>
  );
}
export function Calculator() {
  let COPLocale = Intl.NumberFormat("es-CO");
  const monthRate = 0.0117149169198534;
  const numPeriods = 240;
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const propertyPayments = apiUrl + "/property/external_property_payments";

  const [validation, setValidation] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [downPaymentError, setDownPaymentError] = useState(false);
  const [minPayment, setMinPayment] = useState([]);
  const [outOfRange, setOutOfRange] = useState(false);
  const [downPayment, setDownPayment] = useState(null);
  const [simulatedPayments, setSimulatedPayments] = useState(null);
  const [propertyPrice, setPropertyPrice] = useState(null);
  const [completePayment, setCompletePayment] = useState(false);
  const [paymentMonths, setPaymentMonths] = useState(30);

  const handleChangeDownPayment = (values) => {
    const { value } = values;
    setDownPayment(parseInt(value));
  };
  const handleChangePropertyPrice = (values) => {
    const { value } = values;
    setPropertyPrice(parseInt(value));
  };

  const handleChangePaymentMonths = (event) => {
    setPaymentMonths(parseInt(event.target.value));
    console.log(parseInt(event.target.value));
  };

  const [show, setShow] = useState(false);
  const [generalShow, setGeneralShow] = useState(false);

  const handleGeneralShow = () => {
    setValidation(true);
  };

  const handleShow = () => {
    setCalculate(true);
  };
  const handleClose = () => {
    setCalculate(false);
    setShow(false);
  };
  const [calculate, setCalculate] = useState(false);

  useEffect(() => {
    if (validation) {
      if (isNaN(parseFloat(propertyPrice)) || isNaN(parseInt(paymentMonths))) {
        setErrorMsg(true);
        return;
      }
      if (
        parseFloat(propertyPrice) < 100000000 ||
        parseFloat(propertyPrice) > 900000000
      ) {
        setOutOfRange(true);
        setErrorMsg(false);
        return;
      }
      if (
        (parseFloat(downPayment) >= propertyPrice * 0.2) |
        (parseFloat(downPayment) < propertyPrice * 0.1)
      ) {
        setGeneralShow(false);
        return;
      }

      setMinPayment(
        getMinPaymentValue(propertyPrice, downPayment, paymentMonths)
      );
      setGeneralShow(true);
    }
  }, [validation]);

  useEffect(() => {
    setGeneralShow(false);
    setValidation(false);
    if (
      parseFloat(downPayment) >= propertyPrice * 0.2 &&
      !propertyPrice >= 19000000 &&
      !propertyPrice <= 500000000
    ) {
      setCompletePayment(true);
      setOutOfRange(false);
      setErrorMsg(false);
      setDownPaymentError(false);
      return;
    } else if (parseFloat(downPayment) < propertyPrice * 0.1) {
      setCompletePayment(false);
      setOutOfRange(false);
      setErrorMsg(false);
      setDownPaymentError(true);
      return;
    } else {
      setErrorMsg(false);
      setOutOfRange(false);
      setCompletePayment(false);
      setDownPaymentError(false);
    }
  }, [downPayment, propertyPrice, paymentMonths]);

  useEffect(() => {
    if (simulatedPayments) {
      setShow(true);
    }
  }, [simulatedPayments]);

  useEffect(() => {
    function getPayments() {
      axios
        .get(propertyPayments, {
          params: {
            property_cost: parseInt(propertyPrice),
            ...(downPayment
              ? { down_payment: parseInt(downPayment) }
              : { down_payment: 0 }),
            principal_payment: parseInt(minPayment[0]),
          },
        })
        .then((response) => {
          setSimulatedPayments(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (calculate) {
      getPayments();
    }
  }, [calculate]);

  return (
    <>
      <Container className="clac-view p-0 m-0 overflow-hidden" fluid>
        <Row className="justify-content-center m-0 p-0">
          <Col sm={8} className="mt-5">
            <Row className="calc-row">
              <Col className="mb-5" md={5} xs={12}>
                <Row>
                  <h2 className="bolder my-4 white blue">Haz tus calculos.</h2>
                </Row>
                <Row className="align-items-center">
                  <h5 className="bold mb-0 mt-5 blue">
                    Valor de la casa de tus sueños:
                  </h5>
                  <NumericFormat
                    thousandSeparator={true}
                    prefix={"$"}
                    onValueChange={handleChangePropertyPrice}
                    className={`land-form-control form-control mb-2 ${
                      propertyPrice ? "full" : ""
                    }`}
                    value={propertyPrice}
                    placeholder="Entre $100.000.000 y $900.000.000"
                  />
                  {outOfRange && (
                    <div className="error-msg">
                      El valor debe estar entre $100.000.000 y $900.000.000.
                    </div>
                  )}
                  {errorMsg && (
                    <div className="error-msg">
                      Lo sentimos, los valores ingresados no son válidos o
                      existen campos vacíos. Por favor verifica.
                    </div>
                  )}
                  <h5 className=" bold mb-1 mt-3 blue">Cuota inicial:</h5>
                  <p className="mb-0">
                    La cuota inicial ayuda a acortar la duración del programa
                    y/o disminuir los pagos mensuales.
                  </p>
                  <NumericFormat
                    thousandSeparator={true}
                    prefix={"$"}
                    onValueChange={handleChangeDownPayment}
                    className={`land-form-control form-control mb-2 ${
                      propertyPrice ? "full" : ""
                    }`}
                    value={downPayment}
                    placeholder="Ingresa tu ahorro disponible para la cuota inicial"
                  />
                  {completePayment && (
                    <div className="success-msg">
                      Con los datos ingresados, ya puedes conseguir un crédito
                      de vivienda con un banco. Contáctanos y te asesoraremos en
                      el proceso.
                    </div>
                  )}
                  {downPaymentError && (
                    <div className="error-msg">
                      cuota inicial mínima debe ser el 10% del inmueble.
                    </div>
                  )}
                  <h5 className=" bold mb-1 mt-3 blue">Plazo</h5>
                  <p className="mb-0">
                    Elige si quieres acabar el programa con nosotros en un plazo
                    corto o en un plazo largo.
                  </p>
                  <select
                    name="plazo"
                    id="plazo"
                    className="mb-1 land-form-control form-select"
                    onChange={handleChangePaymentMonths}
                  >
                    <option value="30">Corto plazo & mayor cuota</option>
                    <option value="60">Largo plazo & menor cuota</option>
                  </select>
                  <button
                    className="btn-rounded-green mt-4 calc-btn"
                    onClick={handleGeneralShow}
                  >
                    Calcular
                  </button>
                </Row>
              </Col>
              <Col className="results-col" md={5} xs={12}>
                {generalShow && (
                  <>
                    <h2 className="bolder  mb-4">Resultados</h2>
                    <div>
                      <h3 className="fs-5 bold">Duración:</h3>
                      <p className="p-val">
                        {calculateNumberOfMonths(
                          minPayment,
                          propertyPrice,
                          downPayment
                        )}
                      </p>
                    </div>
                    <div>
                      <h3 className="fs-5 bold">Pago Mensual:</h3>
                      <p className="p-val">
                        $
                        {COPLocale.format(
                          (minPayment[0] + minPayment[1]).toFixed(0)
                        )}{" "}
                        / mes
                      </p>
                    </div>
                    <div className="rent">
                      <div className="point"></div>
                      <div className="point-info">
                        <p className="point-t">Costo financiero Toperty</p>
                        <p className="point-v">
                          ${COPLocale.format(minPayment[1].toFixed(0))}
                        </p>
                      </div>
                    </div>
                    <div className="cap-cont" sm={11}>
                      <div className="point"></div>
                      <div className="point-info">
                        <p className="point-t">Aporte a la compra de la casa</p>
                        <p className="point-v">
                          ${COPLocale.format(minPayment[0].toFixed(0))}
                        </p>
                      </div>
                    </div>
                    <div className="comp-data-bar">
                      <div className="background"></div>
                      <div
                        className="value"
                        style={{
                          width: `${
                            (minPayment[0] / (minPayment[0] + minPayment[1])) *
                            100
                          }%`,
                        }}
                      ></div>
                    </div>
                    <button
                      className="btn-rounded-results mt-2 calc-btn"
                      onClick={handleShow}
                    >
                      Ver detalle de pagos
                    </button>
                    {show && (
                      <NewModal
                        simulatedPayments={simulatedPayments}
                        show={show}
                        handleClose={handleClose}
                        downPayment={downPayment}
                        principalPayment={minPayment[0]}
                      />
                    )}
                    <hr className="my-4" />
                    <div>
                      <h5 className="fs-5 bold">
                        Hipoteca comparable por mes:
                      </h5>
                      <div className="mortgage">
                        <p> Hipoteca 20 años / 15% e.a</p>
                        <p className="m-price">
                          {" "}
                          $
                          {COPLocale.format(
                            calculateMortgagePayment(
                              monthRate,
                              numPeriods,
                              propertyPrice
                            ).toFixed(0)
                          )}
                        </p>
                      </div>
                      <div className="month-val">
                        <p>
                          (Ahorra al mes con Toperty{" "}
                          <b>
                            {" "}
                            $
                            {COPLocale.format(
                              (
                                calculateMortgagePayment(
                                  monthRate,
                                  numPeriods,
                                  propertyPrice
                                ) -
                                (minPayment[0] + minPayment[1])
                              ).toFixed(0)
                            )}
                          </b>
                          )
                        </p>
                      </div>
                    </div>
                  </>
                )}
                {!generalShow && (
                  <>
                    <div className="hidden">
                      <h2 className="bolder  mb-4">Resultados</h2>
                      <div>
                        <h3 className="fs-5 bold">Duración:</h3>
                        <p className="p-val">53 meses</p>
                      </div>
                      <div>
                        <h3 className="fs-5 bold">Pago Mensual:</h3>
                        <p className="p-val">$3.453.000 / mes</p>
                      </div>
                      <div className="rent">
                        <div className="point"></div>
                        <div className="point-info">
                          <p className="point-t">Arriendo</p>
                          <p className="point-v">$2.417000</p>
                        </div>
                      </div>
                      <div className="cap-cont" sm={11}>
                        <div className="point"></div>
                        <div className="point-info">
                          <p className="point-t">
                            Aporte a la compra de la casa
                          </p>
                          <p className="point-v">$1.035.000</p>
                        </div>
                      </div>
                      <div className="comp-data-bar">
                        <div className="background"></div>
                        <div className="value"></div>
                      </div>
                      <button
                        className="btn-rounded-green mt-4 calc-btn"
                        onClick={handleShow}
                      >
                        Ver detalle de pagos
                      </button>
                      <hr className="my-4" />
                      <div>
                        <h5 className="fs-5 bold">
                          Hipoteca comparable por mes:
                        </h5>
                        <div className="mortgage">
                          <p> Hipoteca 15 años / 14% e.a</p>
                          <p className="m-price"> $3.500.000 </p>
                        </div>
                        <div className="month-val">
                          <p>
                            (Ahorra al mes con Toperty <b> $153.000</b>)
                          </p>
                        </div>
                      </div>
                    </div>
                    <h2 className="fs-5 from-msg">
                      Llena el formulario para descubrir el valor a pagar
                      mensual y la duración del programa.
                    </h2>
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5 mx-0 p-0">
          <Col className="mb-5" sm={8}>
            <p className="fs-12 bold">Información legal</p>
            <p className="fs-12 lgl">
              Toperty SAS, pone a disposición el presente Simulador, el cual
              tiene fines informativos y no comporta oferta o promesa de
              contratar. Este simulador es indicativo y está destinado a
              proporcionar información y estimaciones de carácter general
              basadas en la información proporcionada por el usuario. El
              resultado de esta simulación no comprende una certificación o
              recomendación comercial, contable, tributaria o legal. Los
              términos de esta simulación son suministrados con base en las
              condiciones comerciales y de mercado que han sido establecidas
              para la fecha en que se realiza, y sujeta a disponibilidad de los
              inmuebles. Los datos suministrados en el presente simulador serán
              tratados conforme la política de tratamiento de datos de Toperty,
              la cual podrá ser consultada en{" "}
              <a href="https://toperty.co/politica-privacidad" className="blue">
                <u>nuestra política de tratamiento de datos</u>
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}
