import React, { useState } from "react";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Whatsapp } from "./Components/Whatsapp";
import { Calculator } from "./Pages/Calculator";
import { StoreProvider } from "./utils/store";
import { Navbarcomp as Nav } from "./base_styles/components/navbar/Navbarcomp";
import { Footer } from "./base_styles/components/footer/Footer";

const tagManagerArgs = {
  gtmId: "GTM-NCJ2P643",
};

TagManager.initialize(tagManagerArgs);


export function App() {
  /* const [invert, setInvert] = useState(true); */
  const [successpage, setSuccesspage] = useState(false);
  const [nonavpage, setNonavpage] = useState(false);
  const [bgfooter, setBgfooter] = useState("bg-cream");
  const [pathname, setPathname] = useState("/");
  window.onload = function () {
    let pathname = window.location.pathname;
    setPathname(window.location.pathname);
    if (pathname === "/") setBgfooter("bg-light-purple");
    else if (pathname === "/calculator") setBgfooter("bg-white");
    else if (
      pathname === "/faq" ||
      pathname === "/marketplace" ||
      pathname === "/login" ||
      pathname === "/team" ||
      pathname === "/investor" ||
      pathname.includes("investment") ||
      pathname.includes("property")
    )
      setBgfooter(" ");
    else if (pathname === "/app") setBgfooter("bg-light-blue");
    /* pathname === "/" ? setInvert(true) : setInvert(false); */
    pathname.indexOf("/form") === 0 || pathname === "/login"
      ? setNonavpage(true)
      : setNonavpage(false);
    window.location.pathname === "/success"
      ? setSuccesspage(true)
      : setSuccesspage(false);
  };
  return (
    <StoreProvider>
      <Nav
        success={successpage ? "successpage" : "normalpage"}
        nonav={nonavpage ? "nonavpage" : "normalpage"}
        pathname={pathname}
      />
      <React.Suspense>
        <main id="main-component">
          <Router>
            <Routes>
              <Route path="/calculator" element={<Calculator />} />
              <Route path="/" element={<Calculator />} />
            </Routes>
          </Router>
        </main>
      </React.Suspense>
      <Footer />
      <Whatsapp />
    </StoreProvider>
  );
}
