import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { StoreContext } from "../utils/store";
import { columns, financialColumns } from "../utils/tableColumns";
import { CalcCards, FinancialCalcCards } from "../Pages/CalcCards";
import { Table, FinancialTable } from "./Table";
function tooltip(id, text) {
  return (
    <ReactTooltip
      id={id}
      place="top"
      effect="solid"
      type="info"
      className="tooltip"
    >
      {text}
    </ReactTooltip>
  );
}

export default function NewModal(props) {
  let COPLocale = Intl.NumberFormat("es-CO");
  const { isMobile, isDesktop } = useContext(StoreContext);
  const show = props.show;
  let downPayment = props.downPayment;
  if (isNaN(parseFloat(downPayment))) {
    downPayment = 0;
  }
  const simulatedPayments = props.simulatedPayments;
  console.log(props);
  const handleClose = props.handleClose;

  if (Array.isArray(simulatedPayments)) {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <h4 className="bold mb-0">Resultados simulación:</h4>
          <h5 className="gray">Valores en pesos</h5>
          <div className="result">
            <div className="final-value block" data-tip data-for="valorTip">
              <div className="icon"></div>
              <p className="title bold">Valor final</p>
              {tooltip(
                "valorTip",
                "Valor estimado de la propiedad en el mes de salida."
              )}
              <p className="data">
                $
                {COPLocale.format(
                  simulatedPayments[simulatedPayments.length - 1][
                    "Precio del inmueble"
                  ].toFixed(0)
                )}
              </p>
            </div>
            <div
              className="monthly-payment block"
              data-tip
              data-for="precioTip"
            >
              <div className="icon"></div>
              <p className="title bold">Precio compra</p>
              <p className="data">
                $
                {COPLocale.format(
                  (
                    simulatedPayments[simulatedPayments.length - 1][
                      "Precio del inmueble"
                    ] * 0.8
                  ).toFixed(0)
                )}
              </p>
              {tooltip(
                "precioTip",
                "Valor remanente a financiar en el mes de salida para finalizar la compra del inmueble."
              )}
            </div>
            <div
              className="monthly-payment block"
              data-tip
              data-for="pagomensualTip"
            >
              <div className="icon"></div>
              <p className="title bold">Pago mes</p>
              <p className="data">
                $
                {COPLocale.format(
                  simulatedPayments[1]["Pago total"].toFixed(0)
                )}
              </p>
              {tooltip(
                "pagomensualTip",
                "Cuota mensual a pagar durante el programa (incluye arriendo y ahorro)."
              )}
            </div>
            <div className="final-month block" data-tip data-for="mesTip">
              <div className="icon"></div>
              <p className="title bold">Mes salida</p>
              <p className="data">{simulatedPayments.length}</p>
              {tooltip(
                "mesTip",
                "Mes en el que finaliza el programa y el usuario completa el % mínimo de adquisición."
              )}
            </div>
            <div className="ownership block" data-tip data-for="adquisicionTip">
              <div className="icon"></div>
              <p className="title bold">Adquisición(%)</p>
              <p className="data">
                {simulatedPayments[simulatedPayments.length - 1][
                  "Ownership(%)"
                ].toFixed(2)}
                %
              </p>
              {tooltip(
                "adquisicionTip",
                "% de la propiedad de la que es dueño el usuario al final del programa."
              )}
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {isMobile && (
            <div className="calc-cards-container">
              {simulatedPayments.map((payment) => {
                return (
                  <CalcCards
                    cuota={payment["Mes"]}
                    abonoK={payment["Abono a capital"].toFixed(0)}
                    arriendo={payment["Pago de arriendo"].toFixed(0)}
                    total={payment["Pago total"].toFixed(0)}
                    rentas={payment["Ahorro rentas"].toFixed(0)}
                    ownPrice={payment["Ownership($)"].toFixed(0)}
                    ownPerc={payment["Ownership(%)"].toFixed(1)}
                  ></CalcCards>
                );
              })}
            </div>
          )}
          {isDesktop && <Table columns={columns} data={simulatedPayments} />}
        </Modal.Body>
        <Modal.Footer>
          <p className="sm bold dark-purple">Información legal</p>
          <p className="sm">
            Toperty, pone a disposición el presente Simulador, el cual tiene
            fines informativos y no comporta oferta o promesa de contratar. Este
            simulador es indicativo y está destinado a proporcionar información
            y estimaciones de carácter general basadas en la información
            proporcionada por el usuario. El resultado de esta simulación no
            comprende una certificación o recomendación comercial, contable,
            tributaria o legal. Los términos de esta simulación son
            suministrados con base en las condiciones comerciales y de mercado
            que han sido establecidas para la fecha en que se realiza, y sujeta
            a disponibilidad de los inmuebles. Los datos suministrados en el
            presente simulador serán tratados conforme la política de
            tratamiento de datos de Toperty, la cual podrá ser consultada en{" "}
            <a href="https://toperty.co/politica-privacidad">
              <u>nuestra política de tratamiento de datos</u>
            </a>
          </p>
        </Modal.Footer>
      </Modal>
    );
  }
}

export function FinancialModal(props) {
  let COPLocale = Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    maximumFractionDigits: 0,
  });
  const { isMobile, isDesktop } = useContext(StoreContext);
  const show = props.show;
  const investmentValue = props.investmentValue;

  const simulatedPayments = props.simulatedPayments;
  const handleClose = props.handleClose;

  if (Array.isArray(simulatedPayments)) {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <h4 className="bold mb-0">Resultados simulación:</h4>
          <h5 className="gray">Valores en pesos</h5>
          <div className="result">
            <div className="final-value block" data-tip data-for="valorTip">
              <div className="icon"></div>
              <p className="title bold">Valor final</p>
              {tooltip(
                "valorTip",
                "Valor estimado de tu participación en el inmueble " +
                  "en el mes de finalización de esta oportunidad de inversión."
              )}
              <p className="data">
                {COPLocale.format(
                  simulatedPayments[simulatedPayments.length - 1][
                    "Precio del inmueble"
                  ].toFixed(0)
                )}
              </p>
            </div>
            <div
              className="monthly-payment block"
              data-tip
              data-for="precioTip"
            >
              <div className="icon"></div>
              <p className="title bold">Inversión inicial</p>
              <p className="data">{COPLocale.format(investmentValue)}</p>
              {tooltip(
                "precioTip",
                "Valor de la inversión hecha al inicio del proyecto."
              )}
            </div>
            <div
              className="monthly-payment block"
              data-tip
              data-for="pagomensualTip"
            >
              <div className="icon"></div>
              <p className="title bold">Pago mes</p>
              <p className="data">
                {COPLocale.format(
                  simulatedPayments[1]["Pago total"].toFixed(0)
                )}
              </p>
              {tooltip(
                "pagomensualTip",
                "Ingresos mensuales obtenidos durante el programa (incluye arriendo y ahorro)."
              )}
            </div>
            <div className="final-month block" data-tip data-for="mesTip">
              <div className="icon"></div>
              <p className="title bold">Mes salida</p>
              <p className="data">{simulatedPayments.length}</p>
              {tooltip(
                "mesTip",
                "Mes en el que finaliza el programa y el inquilino completa el % mínimo de adquisición."
              )}
            </div>
            <div className="ownership block" data-tip data-for="adquisicionTip">
              <div className="icon"></div>
              <p className="title bold">Venta acumulada(%)</p>
              <p className="data">
                {(
                  simulatedPayments[simulatedPayments.length - 1][
                    "Ownership(%)"
                  ] * 100
                ).toFixed(1)}
                %
              </p>
              {tooltip(
                "adquisicionTip",
                "% de la propiedad de la que es dueño el inquilino al final del programa."
              )}
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {isMobile && (
            <div className="calc-cards-container">
              {simulatedPayments.map((payment) => {
                return (
                  <FinancialCalcCards
                    cuota={payment["Mes"]}
                    total={payment["Pago total"].toFixed(0)}
                    costos={payment["Costos"].toFixed(0)}
                    utilities={payment["Utilidades"].toFixed(0)}
                    ownPerc={(payment["Ownership(%)"] * 100).toFixed(1)}
                    investmentValue={payment["Precio del inmueble"].toFixed(0)}
                  ></FinancialCalcCards>
                );
              })}
            </div>
          )}
          {isDesktop && (
            <FinancialTable
              columns={financialColumns}
              data={simulatedPayments}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <p className="sm bold dark-purple">Información legal</p>
          <p className="sm">
            Toperty, pone a disposición el presente Simulador, el cual tiene
            fines informativos y no comporta oferta o promesa de contratar. Este
            simulador es indicativo y está destinado a proporcionar información
            y estimaciones de carácter general basadas en la información
            proporcionada por el usuario. El resultado de esta simulación no
            comprende una certificación o recomendación comercial, contable,
            tributaria o legal. Los términos de esta simulación son
            suministrados con base en las condiciones comerciales y de mercado
            que han sido establecidas para la fecha en que se realiza, y sujeta
            a disponibilidad de los inmuebles. Los datos suministrados en el
            presente simulador serán tratados conforme la política de
            tratamiento de datos de Toperty, la cual podrá ser consultada en{" "}
            <a href="https://toperty.co/politica-privacidad">
              <u>nuestra política de tratamiento de datos</u>
            </a>
          </p>
        </Modal.Footer>
      </Modal>
    );
  }
}
