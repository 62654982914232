import React from "react";

export function Whatsapp() {
  return (
    <a
      className="whatsapp"
      href="https://api.whatsapp.com/send/?phone=573154092293"
      rel="noreferrer"
      target="_blank"
    >
      <div className="icon"></div>
    </a>
  );
}
